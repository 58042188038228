import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useParams, useSearchParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { mainTimeSelectors, timeSelectorUnits } from '../../../../helpers/optionData';
import { useGraphEnergyReadingData } from '../../../../hooks/useSites';
import { cn } from '../../../../lib/utils';
import Button from '../../../common/button/button';
import BarChartUI from '../../../common/charts/bar-chart';
import LineChartUI from '../../../common/charts/line-chart';
import MultiDateSelector from '../../../common/multi-date-selector';
import SearchableDropdown from '../../../common/searchable-dropdown';

const getDataArrayFromUnit = (tabData, sortedData = []) => {
  const { value, defaultUnit, start_date, end_date } = tabData;
  const { momentUnit: unit } = timeSelectorUnits.find(unit => unit.value === defaultUnit) || { format: 'MMM' };

  let dataStartDate = start_date;
  let dataEndDate = end_date;

  if (value === 'ALL_TIME') {
    const minData = sortedData.at(0);
    const maxData = sortedData.at(-1) || {};
    dataStartDate = minData?.reading_start || start_date;
    dataEndDate = maxData?.reading_start || minData?.reading_start || end_date;
  }

  const data = [];
  let date = moment.unix(dataStartDate).startOf(unit);
  while (date.isBefore(moment.unix(dataEndDate).endOf(unit))) {
    data.push({ start: date.unix(), end: date.clone().add(1, unit).unix() });
    date = date.add(1, unit);
  }
  return data;
};

const prepareData = (data, tabData, readingKeys = []) => {
  if (!data) return [];

  let sortedData = data.flatMap(value => value).sort((a, b) => a.reading_start - b.reading_start);

  let preparedData = getDataArrayFromUnit(tabData, sortedData);

  const defaultReadingKeyData = readingKeys.reduce((acc, key) => ({ ...acc, [key]: 0 }), {});

  const valueData = data.flat().map(value => ({
    ...value,
    date: moment.unix(value.reading_start).unix(),
  }));

  const dataPrepatedWithValue = preparedData.reduce((acc, value) => {
    const data = valueData.filter(data => data.date >= value.start && data.date < value.end);
    if (data.length) {
      acc.push({
        date: value.start,
        start: value.start,
        end: value.end,
        ...data.reduce((acc, value) => ({ ...acc, [value.type]: value?.[value?.type] }), {}),
      });
    } else {
      acc.push({
        date: value.start,
        start: value.start,
        end: value.end,
        ...defaultReadingKeyData,
      });
    }
    return acc;
  }, []);

  return dataPrepatedWithValue;
};

const GraphTimeSelector = ({ timeSelector, setTimeSelector }) => {
  const { value, units, defaultUnit } = timeSelector;

  const getAvailableDateUnitsForCustom = dates => {
    const { start_date, end_date } = dates;
    const start = moment.unix(start_date).clone();
    const end = moment.unix(end_date).clone();

    const availableUnits = timeSelectorUnits.filter(unit => {
      const { durationRequired, momentUnit } = unit;
      if (!durationRequired) return true;
      const { min, max } = durationRequired;
      const diff = end.diff(start, momentUnit);
      return diff >= min && (max ? diff <= max : true);
    });

    return availableUnits;
  };

  const onDateChange = dates => {
    const units = getAvailableDateUnitsForCustom(dates).map(unit => unit.value);
    const customTimeSelector = {
      ...dates,
      label: 'Custom',
      value: 'CUSTOM',
      units: units,
      defaultUnit: units?.[0],
    };
    setTimeSelector({ ...customTimeSelector });
  };

  return (
    <div className="flex items-center col-gap-2">
      <SearchableDropdown
        isCustomSearchable={false}
        isSearchable={false}
        defaultAdditional={{
          defaultOptions: mainTimeSelectors,
        }}
        value={timeSelector}
        onChange={value => setTimeSelector(value)}
        customStyle={{
          control: { minHeight: '32px', maxHeight: '32px', width: '180px' },
          menuList: { maxHeight: 'fit-content' },
        }}
      />
      <MultiDateSelector
        selectedDates={{ start_date: timeSelector.start_date, end_date: timeSelector.end_date }}
        setSelectedDates={dates => onDateChange(dates)}
      />
      <SearchableDropdown
        key={value}
        isCustomSearchable={false}
        isSearchable={false}
        defaultAdditional={{
          defaultOptions: timeSelectorUnits.filter(unit => units.includes(unit.value)),
        }}
        value={timeSelectorUnits.find(unit => unit.value === defaultUnit)}
        onChange={unit => setTimeSelector({ ...timeSelector, defaultUnit: unit.value })}
        customStyle={{
          control: { minHeight: '32px', maxHeight: '32px', width: '130px' },
          menuList: { maxHeight: 'fit-content' },
          dropdownIndicator: { padding: '2px' },
        }}
      />
    </div>
  );
};

const SitePowerGraph = ({
  selectedTabValue = '',
  readingKeys = [],
  keyData = [],
  energyReadings,
  isLoading,
  timeSelector,
}) => {
  const readingData = useMemo(() => readingKeys.map(key => energyReadings[key] || []), [energyReadings, readingKeys]);

  const xAxisFormatter = (value, dataIndex, indexData = null, fromTooltip = false) => {
    const { defaultUnit } = timeSelector;
    if (fromTooltip) {
      switch (defaultUnit) {
        case 'HOUR':
          return moment.unix(value).format('dddd, Do of MMMM HH:mm');
        case 'DAY':
          return moment.unix(value).format('dddd, Do of MMMM');
        default:
          break;
      }
    }

    if (defaultUnit === 'WEEK') {
      const data = indexData || graphData[dataIndex] || {};
      const { start, end } = data || {};
      return `${moment.unix(start).format('DD')} - ${moment.unix(end).format('DD MMMM')}`;
    }
    return moment.unix(value).format(timeSelectorUnits.find(unit => unit.value === defaultUnit)?.format);
  };

  let graphData = prepareData(readingData, timeSelector, readingKeys);
  graphData = graphData.map((data, index) => ({ ...data, formattedDate: xAxisFormatter(data.date, index) }));

  return (
    <div className="flex flex-col flex-1">
      {isLoading ? (
        <Skeleton height={'100%'} containerClassName="w-full h-full letter-spacing-1 radius-2" />
      ) : (
        <div className="flex flex-1 items-center justify-between">
          {selectedTabValue === 'POWER' && (
            <LineChartUI
              data={graphData}
              xAxisDataKey={'date'}
              linesArray={readingKeys.map(key => ({ ...keyData[key], dataKey: key, stackId: 'stack' }))}
              xAxisFormatter={xAxisFormatter}
              yAxisFormatter={value => `${value} kWh`}
            />
          )}
          {selectedTabValue === 'SOLAR_PERFORMANCE' && (
            <BarChartUI
              data={graphData}
              xAxisDataKey={'formattedDate'}
              barArray={readingKeys.map(key => ({ ...keyData[key], dataKey: key, stackId: 'stack' }))}
              xAxisFormatter={xAxisFormatter}
              yAxisFormatter={value => `${value} kWh`}
            />
          )}
        </div>
      )}
    </div>
  );
};

const SitePowerContainer = () => {
  const theme = useTheme();

  const timezone_id = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const { site_id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const include_grid_param = searchParams.get('include_grid');

  const chartTypes = useMemo(
    () => [
      // {
      //   label: 'Power',
      //   value: 'POWER',
      //   readingKeys: ['GENERATION', 'EXPORT', 'SELF_CONSUMPTION', 'GRID_CONSUMPTION'],
      //   keyData: {
      //     GENERATION: { dataKey: 'GENERATION', name: 'Energy Produced', stroke: theme.primary_500 },
      //     EXPORT: { dataKey: 'EXPORT', name: 'Energy Exported', stroke: theme.success_500 },
      //     SELF_CONSUMPTION: { dataKey: 'SELF_CONSUMPTION', name: 'Energy Consumption', stroke: theme.orange_500 },
      //     GRID_CONSUMPTION: { dataKey: 'GRID_CONSUMPTION', name: 'Energy Sold to the Grid', stroke: theme.error_500 },
      //   },
      //   isDisabled: true,
      // },
      {
        label: 'Solar Performance',
        value: 'SOLAR_PERFORMANCE',
        readingKeys: [
          'SOLAR_CONSUMPTION',
          'EXPORT',
          'BATTERY_CHARGE_SOLAR',
          include_grid_param ? 'BATTERY_CHARGE_GRID' : null,
        ].filter(Boolean),
        keyData: {
          SOLAR_CONSUMPTION: { dataKey: 'SOLAR_CONSUMPTION', name: 'Solar Consumption', fill: theme.primary_500 },
          GRID_CONSUMPTION: { dataKey: 'GRID_CONSUMPTION', name: 'Grid Consumption', fill: theme.yello_100 },
          EXPORT: { dataKey: 'EXPORT', name: 'Export', fill: theme.blue_100 },
          BATTERY_CHARGE_SOLAR: {
            dataKey: 'BATTERY_CHARGE_SOLAR',
            name: 'Battery Charge Solar',
            fill: theme.success_100,
          },
          BATTERY_DISCHARGE: { dataKey: 'BATTERY_DISCHARGE', name: 'Battery Discharge', fill: theme.primary_100 },
          BATTERY_CHARGE_GRID: { dataKey: 'BATTERY_CHARGE_GRID', name: 'Battery Charge Grid', fill: theme.error_500 },
        },
      },
    ],
    [theme, include_grid_param],
  );

  const defaultTimeSelector = mainTimeSelectors[0];

  const [selectedTab, setSelectedTab] = useState(chartTypes.find(type => type.value === 'SOLAR_PERFORMANCE'));
  const [timeSelector, setTimeSelector] = useState(defaultTimeSelector);

  const { start_date, end_date, defaultUnit: unit } = timeSelector;
  const { readingKeys, keyData, value } = selectedTab;

  const [energyReadings, setEnergyReadings] = useState({});

  const results = useGraphEnergyReadingData({
    energyTypes: selectedTab.readingKeys,
    params: { site_id, start_date, end_date, unit, timezone_id },
  });

  const isFetching = results.some(result => result.isFetching);

  useEffect(() => {
    const data = results.reduce((acc, result) => {
      const { data: resultData } = result || {};
      if (!resultData) {
        return acc;
      }
      const { key, data } = resultData || {};
      return {
        ...acc,
        [key]: data.map(value => ({ ...value, [key]: value?.value_kwh, type: key, value_kwh: null })),
      };
    }, {});
    setEnergyReadings(data);
  }, [results]);

  useEffect(() => {
    if (energyReadings) {
      const refetchFunc = results.map(result => result.refetch);
      refetchFunc.forEach(func => func());
    }
  }, [timeSelector, selectedTab]);

  return (
    <SitePowerContainerWrapper className="flex flex-col rounded-md border flex-1 px-6 py-6 gap-y-6">
      <div className="flex items-center w-full gap-x-4">
        <div className="flex items-center">
          {chartTypes.map(({ label, value, isDisabled, ...rest }) => (
            <Button
              key={value}
              label={label}
              disabled={isDisabled}
              width="fit-content"
              borderRadius="6px"
              className={cn('transparent specified-width px-3 py-2 one-line', {
                'primary-blue': selectedTab.value === value,
              })}
              onClick={() => setSelectedTab({ value, label, isDisabled, ...rest })}
            />
          ))}
        </div>
        <GraphTimeSelector timeSelector={timeSelector} setTimeSelector={setTimeSelector} />
      </div>
      <SitePowerGraph
        selectedTabValue={value}
        readingKeys={readingKeys}
        keyData={keyData}
        energyReadings={energyReadings}
        isLoading={isFetching}
        timeSelector={timeSelector}
        setTimeSelector={setTimeSelector}
      />
    </SitePowerContainerWrapper>
  );
};

const SitePowerContainerWrapper = styled.div`
  .power-tab {
    border-left: 1px solid transparent;

    &.tab-start {
      border-radius: 100px 0 0 100px;
      border-left: 1px solid ${({ theme }) => theme.natural_200};
    }

    &.tab-end {
      border-radius: 0 100px 100px 0;
      border-right: 1px solid ${({ theme }) => theme.natural_200};
    }

    &.selected-power-tab {
      border: 1px solid ${({ theme }) => theme.primary_500};
      background-color: ${({ theme }) => theme.primary_50};
    }
  }
`;

export default SitePowerContainer;
